<template>
  <router-link
    :to="link()"
    v-slot="{ href, navigate, isActive, isExactActive }"
    v-permission="navitem.permissions"
  >
    <li
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item"
      :class="[
        isActive && 'menu-item-active',
        isExactActive && 'menu-item-active',
        hasActivePrefix(navitem.mainNavPrefix) && 'menu-item-active'
      ]"
    >
      <a :href="href" class="menu-link" @click="navigate">
        <i v-if="navitem.icon" class="menu-icon" :class="navitem.icon"></i>
        <i v-else class="menu-bullet" :class="bullet()"><span></span></i>
        <span class="menu-text">{{
          navitem.translate ? $t(navitem.translate) : navitem.title
        }}</span>
      </a>
    </li>
  </router-link>
</template>

<script>
import { menuMixin } from "@/core/mixins/menuMixin.js";

export default {
  name: "KTMenuItemText",
  mixins: [menuMixin],
  components: {},
  props: {
    navitem: Object
  },
  methods: {
    hasActivePrefix(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  }
};
</script>
