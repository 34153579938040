<template>
  <KTMenuItemText
    v-bind:navitem="navitem"
    v-if="!navitem.submenu"
  ></KTMenuItemText>

  <KTMenuSubmenu
    v-else-if="_.size(navitem.submenu) > 0"
    v-bind:submenu="navitem.submenu"
    v-bind:parentMenu="navitem"
  ></KTMenuSubmenu>
</template>

<script>
import KTMenuItemText from "@/view/layout/aside/components/MenuItemText.vue";
import KTMenuSubmenu from "@/view/layout/aside/components/MenuSubmenu.vue";

export default {
  name: "KTMenuItem",
  components: {
    KTMenuItemText,
    KTMenuSubmenu
  },
  props: {
    navitem: Object,
    userPermissions: Array
  },
  methods: {},
  computed: {}
};
</script>
