<template>
  <li
    aria-haspopup="true"
    data-menu-toggle="hover"
    class="menu-item menu-item-submenu"
    v-bind:class="{ 'menu-item-open': hasActiveChildren(parentMenu.page) }"
    v-permission="parentMenu.permissions"
  >
    <a href="#" class="menu-link menu-toggle">
      <i v-if="parentMenu.icon" class="menu-icon" :class="parentMenu.icon"></i>
      <span class="menu-text">
        {{
          parentMenu.translate ? $t(parentMenu.translate) : parentMenu.title
        }}</span
      >
      <i class="menu-arrow"></i>
    </a>
    <div class="menu-submenu">
      <span class="menu-arrow"></span>
      <ul class="menu-subnav">
        <li aria-haspopup="true" class="menu-item menu-item-parent">
          <span class="menu-link">
            <span class="menu-text">{{
              parentMenu.translate ? $t(parentMenu.translate) : parentMenu.title
            }}</span>
          </span>
        </li>

        <template v-for="(navitem, i) in submenu">
          <KTMenuItemText v-bind:navitem="navitem" :key="i"></KTMenuItemText>
        </template>
      </ul>
    </div>
  </li>
</template>

<script>
import KTMenuItemText from "@/view/layout/aside/components/MenuItemText.vue";

import { menuMixin } from "@/core/mixins/menuMixin.js";

export default {
  name: "KTMenuSubmenu",
  mixins: [menuMixin],
  components: {
    KTMenuItemText
  },
  props: {
    submenu: Array,
    parentMenu: Object
  },
  methods: {},
  computed: {}
};
</script>
