<template
  ><li
    aria-haspopup="true"
    data-menu-toggle="click"
    class="menu-item menu-item-submenu menu-item-open-dropdown"
    v-bind:class="{ 'menu-item-active': hasActiveChildren(parentMenu.page) }"
    v-permission="parentMenu.permissions"
  >
    <a href="#" class="menu-link menu-toggle">
      <i v-if="parentMenu.icon" class="menu-icon" :class="parentMenu.icon"></i>
      <i v-else class="menu-bullet" :class="bullet()"><span></span></i>
      <span class="menu-text">
        {{ parentMenu.translate ? $t(parentMenu.translate) : parentMenu.title }}
      </span>
    </a>
    <div class="menu-submenu menu-submenu-fixed">
      <div class="menu-subnav megamenu" style="width: 1000px;">
        <ul class="menu-content">
          <template v-for="(submenuitem, i) in submenu">
            <KTMenuSection
              v-permission="submenuitem.permissions"
              v-bind:submenuitem="submenuitem"
              :key="i"
            ></KTMenuSection>
          </template>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
import KTMenuSection from "@/view/layout/header/components/MenuSection.vue";

import { menuMixin } from "@/core/mixins/menuMixin.js";

export default {
  name: "KTMenuSubmenu",
  mixins: [menuMixin],
  components: {
    KTMenuSection
  },
  props: {
    submenu: Array,
    parentMenu: Object
  },
  methods: {},
  computed: {}
};
</script>
