<template>
  <ul class="menu-nav">
    <template v-for="(navitem, i) in menuItems">
      <template v-permission="navitem.permissions">
        <KTMenuItem :navitem="navitem" :key="i"></KTMenuItem>
      </template>
    </template>
  </ul>
</template>

<script>
import KTMenuItem from "@/view/layout/header/components/MenuItem.vue";
import menuConfig from "@/core/config/menu.config.json";

import { menuMixin } from "@/core/mixins/menuMixin.js";

export default {
  name: "KTMenu",
  mixins: [menuMixin],
  components: {
    KTMenuItem
  },
  computed: {
    menuItems: () => {
      return menuConfig.header.menu;
    }
  },
  methods: {}
};
</script>
